<template>
  <Login v-if="$route.name == 'login'" />
  <div v-if="$route.name !== 'login'">
    <div id="main-container" class="container-fluid p-2 responsive-y">
      <div id="main-card" class="p-1 responsive-y card shadow">
        <div class="btn-group btn-back shadow-0">
          <button
            @click="back()"
            class="btn btn-sm btn-primary rounded"
            v-if="$route.meta.iscomponent"
          >
            <i class="fa fa-arrow-left"></i>
          </button>
          <button
            @click="$router.push('/')"
            class="btn btn-sm btn-secondary rounded mx-1"
          >
            <i class="fa-solid fa-right-from-bracket"></i>
          </button>
          <button
            class="btn btn-sm btn-secondary rounded"
            data-mdb-toggle="modal"
            data-mdb-target="#user"
            @click="get"
          >
            <i class="fa-solid fa-user"></i>
          </button>
        </div>
        <Router-view />
      </div>
    </div>
  </div>
  <Loading v-if="loading" key="0" />
  <User :data="user" @update_user="update_user" />
</template>

<script>
import Loading from "@/components/Utils/Loading.vue";
import User from "./components/Utils/User.vue";
import Login from "./views/Login/Login.vue";
import {
  catchError,
  disabled,
  success,
  this_user,
  update_user,
} from "./components/Utils/api";
export default {
  name: "App",
  components: { Login, Loading, User },
  data() {
    return {
      nightMode: localStorage.getItem("nightMode"),
      user: {},
    };
  },
  watch: {
    $route(to) {
      document.title = `${to.meta.title}`;
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    get() {
      this.$store.dispatch("setloading", { loading: true });
      this_user()
        .then((e) => {
          this.user = e.data;
          this.$store.dispatch("setloading", { loading: false });
        })
        .catch((error) => {
          this.$store.dispatch("setloading", { loading: false });
          catchError(error);
        });
    },
    update_user(e) {
      disabled(true);
      update_user(e)
        .then(() => {
          disabled(false);
          success("user-modal").then(() => {
            this.get();
          });
        })
        .catch((error) => {
          disabled(false);
          catchError(error);
        });
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading.loading;
    },
  },
};
</script>

<style>
@import url("./components/Style/main.css");
</style>
