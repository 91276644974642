<template>
  <div class="modal fade" id="user">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Изменить информацию о пользователе</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <form @submit.prevent="$emit('update_user', body)">
          <div class="modal-body">
            <div class="form-group">
              <label for="user_name">Имя: </label>
              <input
                type="text"
                id="user_name"
                class="form-control"
                v-model="body.name"
                required
              />
            </div>
            <div class="form-group">
              <label for="username">Имя пользователя: </label>
              <input
                type="text"
                id="username"
                class="form-control"
                v-model="body.username"
                required
              />
            </div>
            <div class="form-group">
              <label for="password">Пароль: </label>
              <input
                type="password"
                id="password"
                class="form-control"
                v-model="body.password"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-mdb-dismiss="modal"
              user-modal
            >
              Отменить
            </button>
            <button type="submit" class="btn btn-primary">Сохранить</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update_user"],
  props: ["data"],
  name: "User",
  data() {
    return {
      body: {
        id: null,
        name: "",
        username: "",
        password: "",
      },
    };
  },
  watch: {
    data(e) {
      this.body = {
        id: e.id,
        name: e.name,
        username: e.username,
        password: "",
      };
    },
  },
};
</script>
