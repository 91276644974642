import { createRouter, createWebHistory } from "vue-router";
const role = localStorage.getItem("role");

let routes = [
  {
    path: "/categories",
    name: "categoryies",
    meta: { auth: true, title: "Категория" },
    component: () => import("../views/Category.vue"),
  },
  {
    path: "/foods",
    name: "foods",
    meta: { auth: true, title: "Еда", iscomponent: true },
    component: () => import("../views/Food.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    meta: { auth: false, title: "Sahifa Topilmadi" },
    component: () => import("../components/Utils/NotFound.vue"),
  },
  {
    path: "/",
    name: "login",
    meta: { auth: false, title: "Login" },
    component: () => import("../views/Login/Login.vue"),
  },
];

routes = routes.filter((route) => {
  if (route.meta.role) {
    return route.meta.role.includes(role);
  } else {
    return route;
  }
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("access_token") !== null;

  if (to.matched.some((record) => record.meta.auth)) {
    if (!loggedIn) {
      next("/");
    }
  }
  next();
});

export default router;
